@import "~simplebar/dist/simplebar.css";
@import "~highlight.js/styles/androidstudio.css";
// Hop Core
@import "./@hop/styles/core";
@import "../node_modules/quill/dist/quill.snow.css";
@import "./@hop/styles/partials/plugins/_quill.scss";

/*
  You can override any CSS Variable or style here
  Example:

  :root {
   --sidenav-background: #333333;
  }

  All possible variables can be found in @hop/styles/core or by simply inspecting the element you want to change
  in Chrome/Firefox DevTools
*/

/**
  Uncomment the below code and adjust the values to fit your colors, the application will automatically adjust
 */
:root {
  // --color-primary: blue;
  // --color-primary-contrast: white;

  // --color-accent: yellow;
  // --color-accent-contrast: black;

  // --color-warn: yellow;
  // --color-warn-contrast: black;
  .blue-snackbar {
    background: #2196F3;
  }

  .red-snackbar {
    background: #f3212f;
  }

  .green-snackbar {
    background: #0ecd2d;
  }
}
